import React from "react";

import { PortableText } from "@portabletext/react";
import { graphql, Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import Seo from "../../components/Seo/Seo";
import Umbrella from "../../components/Umbrella/Umbrella";
import { ArrowRight24Icon, MessageIcon } from "../../icons";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";

const IndexPage = ({ data: { page } }) => {
  const { language } = useI18next();
  const langPath = language.substring(0, 2);

  return (
    <MainLayout
      header={(
        <Header
          headline={page.headline}
          text={<PortableText value={page._rawDescription} />}
          cta={(
            <Button
              size="small"
              title="Work with us"
              color="white"
              variant="primary"
              href="/contact"
            >
              Work with us <MessageIcon />
            </Button>
          )}
          variant="blank"
          theme="dark"
          bgImageUrl="url(/images/backgrounds/start.webp)"
          bgVideo="home"
          umbrella={(
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-30 gap-y-15 w-full">
              <Umbrella
                animate="hover"
                label="What we do"
                color="white"
                icons={<ArrowRight24Icon />}
                to={`/${langPath}/what-we-do`}
                title="what_we_do"
                as={Link}
              />
              <Umbrella
                animate="hover"
                label="What we did"
                color="white"
                icons={<ArrowRight24Icon />}
                to={`/${langPath}/what-we-did`}
                title="what_we_did"
                as={Link}
              />
              <Umbrella
                animate="hover"
                label="Who we are"
                color="white"
                icons={<ArrowRight24Icon />}
                to={`/${langPath}/who-we-are`}
                title="who_we_are"
                as={Link}
              />
            </div>
          )}
        />
      )}
    >
      <AllComponents elements={page.contentElements} />
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) { 
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityHomepage(id: {eq: $id}) {
      i18n_lang
      id
      title
      headline
      _rawDescription
      slug {
        current
      }
      contentElements {
        ...SectionHeader
        ...ServiceCardList
        ...SolutionCardList
        ...OfficeCardList
        ...ContentCardList
        ...CtaCard
        ...ScrollVideoCard
        ...ContentSection
        ...ContactSection
        ...ContentModuleEmbed
      }
    }
 }
`;

IndexPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default IndexPage;
